<template>
  <div>
    <TopParallax
      title="EVENTS"
      :img-src="imgSrc"
    />
    <EventsContent />
  </div>
</template>

<script>
import TopParallax from '@/components/TopParallax.vue'
import EventsContent from '@/components/hope/EventsContent.vue'

export default {
  components: {
    TopParallax,
    EventsContent
  },
  data() {
    return {
      imgSrc: require('@/assets/images/Video_Placeholder.jpg')
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Events'
      }
    }
  }
}
</script>
