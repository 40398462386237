<template>
  <v-carousel
    class="pa-0"
    cycle
    height="auto"
    :show-arrows-on-hover="true"
    :hide-delimiters="false"
    delimiter-icon="mdi-minus"
  >
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
      reverse-transition="fade-transition"
      fade
      eager
    >
      <v-img :src="item.src" eager/>
    </v-carousel-item>
  </v-carousel>
</template>
<script>
export default {
  data () {
    return {
      items: [
        {
          src: require('@/assets/images/schoolCarousel1/Food Distribution.jpg')
        },
        {
          src: require('@/assets/images/schoolCarousel1/Food Donation to Mama.jpg')
        },
        {
          src: require('@/assets/images/schoolCarousel1/Food Donations .jpg')
        },
        {
          src: require('@/assets/images/schoolCarousel1/Food Lineup.jpg')
        },
        {
          src: require('@/assets/images/schoolCarousel1/IMG-20200405-WA0012.jpg')
        },
        {
          src: require('@/assets/images/schoolCarousel1/IMG-20200508-WA0014.jpg')
        },
        {
          src: require('@/assets/images/schoolCarousel1/IMG-20201222-WA0012.jpg')
        },
        {
          src: require('@/assets/images/schoolCarousel1/IMG-20201222-WA0024.jpg')
        },
        {
          src: require('@/assets/images/schoolCarousel1/IMG-20201222-WA0025.jpg')
        },
        {
          src: require('@/assets/images/schoolCarousel1/Photo from Debra Bates.jpg')
        }
      ]
    }
  }
}
</script>