<template>
  <div>
    <v-card rounded="0">
      <v-container class="py-4">
        <h4 class="h4 text-center">COVID UPDATE - KEEPING THE CHILDREN HEALTHY AND SAFE</h4>
        <v-divider class="mb-4" />
        <v-row>
          <v-col cols="12" lg="7">
            <h5 class="h5 mb-4">The first cases of covid in Angola were reported on March 21, 2020. Soon afterwards, the country went into lockdown and instituted the same types of restrictions as were mandated in Canada. That meant our Hope and Angels children were unable to attend school during the lockdown period.</h5>
            <h5 class="h5 my-2"><b>HOW DID HOPE AND ANGELS RESPOND TO NEEDS DURING COVID LOCKDOWN?</b></h5>
            <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>Food and supplies were delivered to all of our children and their families four times during the lockdown.</h5>
            <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>The needs of the children and their families were assessed regularly by Hope and Angels volunteers.</h5>
            <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>Safety plans were developed by the staff at our Hope and Angels Centre in Luanda (Fubu) awaiting the reopening of schools.</h5>
            <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>Salaries of the teachers and cook at the Centre continued to be paid by our Foundation despite lockdown and school closures. This display of appreciation to these dedicated employees sent a clear message that their efforts with the children are greatly recognized and valued.</h5>
            <h5 class="h5 my-2"><v-icon>mdi-circle-medium</v-icon>Throughout covid lockdown, staff members continuously assessed the needs of the children and their families.</h5>
          </v-col>
          <v-col cols="12" lg="5">
            <CovidCarousel class="mt-6" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card rounded="0" color="#f3f2ed">
      <v-container>
        <h4 class="h4 text-center">HOPE AND ANGELS FOUNDATION PROVIDED FOOD IN JUNE, 2020</h4>
        <v-divider />
        <v-row>
          <v-col cols="12" lg="6">
            <h5 class="h5 my-4">Donations from compassionate Canadian supporters meant that all our special children in Luanda and their families received food supplies in June, 2020 from Hope and Angels Foundation. These food donations couldn’t have arrived at a better time and were received with great joy and gratitude by these Angolan families.</h5>
            <h5 class="h5 my-4">Throughout the covid pandemic, the support received from faithful donours has also meant that the teachers and the cook at Hope and Angels School in Luanda have continued to receive their salary. This display of appreciation to these dedicated employees has sent a clear message that their efforts with the children are greatly recognized and valued.</h5>
            <h5 class="h5 my-4">The staff at Hope and Angels School is working diligently at this time developing safety plans for the reopening of the school. As soon as the Angolan government lifts covid restrictions, everything will be in place to have the children return to our special school in Luanda.</h5>
          </v-col>
          <v-col cols="12" lg="6">
            <FoodCarousel class="mt-2" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6">
            <div class="video-container">
              <iframe
                class="responsive-iframe"
                src="https://www.youtube.com/embed/SD9KxOSBrqc?rel=0"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
              ></iframe>
            </div>
          </v-col>
          <v-col cols="12" lg="6">
            <div class="video-container">
              <iframe
                class="responsive-iframe"
                src="https://www.youtube.com/embed/SUeoi-OVu5Y?rel=0"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen=""
              ></iframe>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card rounded="0">
      <v-container class="py-4">
        <h4 class="h4 text-center">REOPENING OF SCHOOLS, APRIL, 2021</h4>
        <v-divider class="mb-4" />
        <v-row>
          <v-col cols="12" lg="6">
            <h5 class="h5 mb-4">Finally, at the beginning of April, 2021, the government allowed schools in Angola to reopen according to strict protocol. This was a very happy day for our Hope and Angels children as well as their teachers.  When the children returned to their classrooms, they found a huge surprise waiting for them.  There were desks in the rooms for them to work on.  The staff at our Centre in Luanda (Fubu) were able to purchase these used desks just in time for classes to resume.</h5>
            <h5 class="h5 my-2">We are so grateful to God for keeping all our precious children safe and healthy. We continue to stand on His promise in Psalm 121 for the safety of the children:</h5>
            <h5 class="h5 text-center"><b>“The Lord is your keeper. The Lord is your shade at your right hand.<br>
              The sun shall not strike you by day, nor the moon by night.<br>
              The Lord shall preserve you from all evil; He shall preserve your soul.<br>
              The Lord shall preserve your going out and your coming in,<br>
              From this time forth and even forevermore.”<br>
              Psalm 121: 5-8
            </b></h5>
          </v-col>
          <v-col cols="12" lg="6">
            <SchoolCarousel1 class="mb-2" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
          </v-col>
          <v-col cols="12" lg="6">
            <SchoolCarousel2 class="my-2" />
          </v-col>
          <v-col cols="3">
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import CovidCarousel from '@/components/hope/CovidCarousel.vue'
import SchoolCarousel1 from '@/components/hope/SchoolCarousel1.vue'
import SchoolCarousel2 from '@/components/hope/SchoolCarousel2.vue'
import FoodCarousel from '@/components/hope/FoodCarousel.vue'

export default {
  components: {
    CovidCarousel,
    SchoolCarousel1,
    SchoolCarousel2,
    FoodCarousel
  }
}
</script>