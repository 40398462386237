<template>
  <div>
    <v-parallax
      dark
      :src="imgSrc"
      height="400"
    >
      <v-overlay absolute opacity="0.3" class="ma-0 pa-0">
        <v-row
          align="center"
          justify="center"
        >
          <h1 :class="[$vuetify.breakpoint.mdAndUp ? 'h1' : 'h2']">
            COVID
          </h1>
          <v-container class="py-0">
            <hr>
          </v-container>
        </v-row>
      </v-overlay>
    </v-parallax>
    <CovidContent />
  </div>
</template>

<script>
import CovidContent from '@/components/hope/CovidContent.vue'

export default {
  components: {
    CovidContent
  },
  data() {
    return {
      imgSrc: require('@/assets/images/mask.jpg')
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Covid'
      }
    }
  }
}
</script>
