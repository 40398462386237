<template>
  <v-carousel
    cycle
    height="auto"
    class="pa-0"
    delimiter-icon="mdi-minus"
  >
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
      reverse-transition="fade-transition"
      fade
      eager
    >
      <v-img :src="item.src" eager/>
    </v-carousel-item>
  </v-carousel>
</template>
<script>
export default {
  data () {
    return {
      items: [
        {
          src: require('@/assets/images/schoolCarousel2/Classroom 1.jpg')
        },
        {
          src: require('@/assets/images/schoolCarousel2/Classroom 2.jpg')
        },
        {
          src: require('@/assets/images/schoolCarousel2/Classroom Teacher.jpg')
        },
        {
          src: require('@/assets/images/schoolCarousel2/H & A Classroom.jpg')
        }
      ]
    }
  }
}
</script>